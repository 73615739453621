<template> 
  <div id="SAT" class="mb-15">

     <img   id="sliderImage" :class="windowWidth >= 1200? 'SliderStyle':'SliderStyleMobile'"  alt="" src="@/assets/images/Patient/surgerybanner.jpg" />
   <div style="height:48vh" v-if="windowWidth >= 1200">
     </div>

<div id="searchDev" :class="windowWidth >= 1200? 'searchPosition':''">
    <div class="vx-row">
      <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("SurgeryAndTreatment") }}
      </p>
    </div>

        <div class="search w-full mt-10">
          <div
            class="whiteBack searchCard"
            :class="[windowWidth > 500 ? 'searchCard' : 'searchCardMobil']">
            
            <vs-row vs-type="flex" vs-w="12" style="margin-top: -30px">
              <vs-col
                :class="[windowWidth > 500 ? '' : 'mt-3']"
                vs-type="flex"
                class=""
                vs-justify="center"
                vs-align="center"
                vs-w="2.3"
                vs-sm="12"
              >
                <feather-icon
                  icon="CommandIcon"
                  class="cursor-pointer m-1"
                ></feather-icon>
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  style="width: 80%; border: none !important"
                  id="specialyMobile"
                  label="Name"
                  v-model="search.SurgeryCategoryID"
                  :options="surgeryCategories"
                  :placeholder="$t('Specialty')"
                  :reduce="(ID) => ID.ID"
                  @input="UpdateSelectedSurgCategoryColor(search.SurgeryCategoryID)"
                />
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w=".2"
                vs-sm="0"
              >
                <div class="divider"></div>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2.3"
                vs-sm="12"
              >
                <feather-icon
                  icon="GitBranchIcon"
                  class="cursor-pointer m-1"
                ></feather-icon>
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  style="width: 80%"
                  id="destinationMobile"
                  label="Name"
                  @input="SimpleSearchHospitals"
                  v-model="search.SurgeryID"
                  :options="surgeries"
                  :placeholder="$t('Surgeries')"
                  :reduce="(ID) => ID.ID"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w=".2"
                vs-sm="0"
              >
                <div class="divider"></div>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2.5"
                vs-sm="12"
              >
                <feather-icon icon="MapPinIcon" class="cursor-pointer m-1"></feather-icon>
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  @input="SimpleSearchHospitals"
                  style="width: 80%"
                  id="destinationMobile"
                  label="Name"
                  v-model="search.CountryID"
                  :options="countries"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('Destination')"
                />
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w=".2"
                vs-sm="0"
              >
                <div class="divider"></div>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2.8"
                vs-sm="12"
              >
                <feather-icon
                  icon="HeadphonesIcon"
                  class="cursor-pointer m-1"
                ></feather-icon>
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  style="width: 80%"
                  label="Name"
                  v-model="search.HospitalID"
                  :options="hospitalsList"
                  :reduce="(ID) => ID.ID"
                  @input="UpdateData()"
                  :placeholder="$t('hospitals')"
                />
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                v-if="windowWidth >= 1200"
                vs-w="1.5"
                vs-sm="0"
              >
                <div
                  class="greenBackColor searchButton w-full"
                  @click="startSearchSurAndTreat"
                >
                  <vs-row vs-w="12" class="mt-5" vs-justify="center" vs-align="center">
                    <vs-col vs-justify="flex-end" vs-align="flex-start" vs-w="4">
                      <h6 class="whitecolor text-bold">
                        {{ $t("Search") }}
                      </h6>
                    </vs-col>
                    <vs-col vs-justify="flex-end" vs-align="center" vs-w="5">
                      <feather-icon
                        style="padding-top: 10px"
                        class="whitecolor ml-5 mr-5"
                        icon="SearchIcon"
                      />
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                class="w-full"
                vs-align="center"
                vs-w="0"
                v-if="windowWidth < 1200"
                vs-sm="12"
                vs-xs="12"
              >
                <div
                  class="greenBackColor w-full searchButtonMobil"
                  @click="startSearchSurAndTreat"
                >
                  <vs-row vs-w="12" class="mt-2" vs-justify="center" vs-align="center">
                    <vs-col vs-justify="flex-end" vs-align="flex-start" vs-w="3">
                      <h4 class="whitecolor text-bold" style="text-align: end;">
                        {{ $t("Search") }}
                      </h4>
                    </vs-col>
                    <vs-col vs-justify="flex-end" vs-align="center" vs-w="3">
                      <feather-icon
                        style="padding-top: 10px"
                        class="whitecolor"
                        icon="SearchIcon"
                      />
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>
</div>
        <div style="width: 100%" class="hospitalListMobil">
          <SurgeryAndTreatmentsList
            :data="hospitals"
            v-if="hospitals.length > 0"
            @getSurgeries="getSurgeries"
          />
        </div>
    <h1 style="text-align:center" v-if="IsShowMore&&hospitals&&hospitals.length>0" @click="getNextHospitals">{{$t('ShowMore')}}</h1> 
     

    <vs-popup fullscreen title="" :active.sync="showPopub">
      <HospitalSurgeries
        :itemModel="ItemModel"
        v-if="showPopub"
        @closePop="showPopub = false"
      ></HospitalSurgeries>
    </vs-popup>
  </div>
</template>
<script>
import moduleSurgery from "@/store/settings/surgery/moduleSurgery.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleSurgeryCategory from "@/store/settings/surgeryCategory/moduleSurgeryCategory.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";

import SurgeryAndTreatmentsList from "./SurgeryAndTreatmentsList.vue";
import { domain } from "@/gloabelConstant.js";
import CustomPaging from "../../../components/CustomPaging.vue";
import HospitalSurgeries from "./HospitalSurgeriesPopup.vue";

export default {
  components: {
    SurgeryAndTreatmentsList,
    CustomPaging,
    HospitalSurgeries,
  },
  data() {
    return {
      showPopub: false,
      EnglishLan: false,
      IsShowMore:true,
      ItemModel: {
        Surgeries: [],
      },
      StatusList: [
        {
          Code: 1,
          Value: "Recommended",
        },
        {
          Code: 2,
          Value: "Highest Price",
        },
        {
          Code: 3,
          Value: "Lowest Price",
        },
        {
          Code: 4,
          Value: "Rate",
        },
      ],
      search: {
        SurgeryCategoryID: "",
        SurgeryID: "",
        CountryID: "",
        HospitalID: "",
        filterID: "",
        PageSize: 10,
        PageNumber: 1,
        SurgeryActive:true
      },

      ActiveIndex: null,
      CountryActiveIndex: null,
      SurgActiveIndex: null,
      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 4,
    };
  },
  methods: {
    UpdateData(){
    this.UpdateSpecialities();
    this.UpdateSurgeries();
    this.GetAllAppointmentSurgeryCountries()
    },
    UpdateSpecialities(){
  this.$store.dispatch("SurgeryCategoryList/SearchSurgeryCategories",this.search);
},
UpdateSurgeries(){
  this.$store.dispatch("SurgeryList/SearchSurgeries",this.search);
},
    startSearchSurAndTreat() {
      this.search.PageNumber = 1;
      this.search.PageSize=10;
      this.IsShowMore = true;
      this.$store.commit("SurgeryList/SET_SearchHospitals", []);

      this.searchSurAndTreat();
    },
    getNextHospitals() {
      // this.CurrentPage = this.CurrentPage + 1;
      this.search.PageNumber++;
      this.searchSurAndTreat();
    },
    getSurgeries(ID, HospitalID) {
      debugger;
      var obj = {};
      obj.SurgeyCategoryID = ID;
      obj.HospitalID = HospitalID;
      this.$store
        .dispatch("SurgeryList/GetAlHospitalSurgeyByCategory", obj)
        .then((res) => {
          if(res.data==undefined)
             this.ItemModel.Surgeries = res[0].data.Data;
          else   
             this.ItemModel.Surgeries = res.data.Data;

          this.ItemModel.CatName = this.ItemModel.Surgeries[0].Surgery.SurgeryCategory.Name;
          this.showPopub = true;
        });
    },
    clearSearch() {
      this.search.SurgeryCategoryID = "";
      this.search.SurgeryID = "";
      this.search.CountryID = "";
      this.search.HospitalID = "";
      this.search.filterID = "";
      this.search.PageSize = 10;
      this.search.PageNumber = 1;

      // this.searchSurAndTreat()
      this.UpdateData();
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },

    back() {
      if (this.hospitals.length == 0) this.$router.go(-1);
      else this.$store.commit("SurgeryList/SET_SearchHospitals", []);
    },
    searchSurAndTreat() {
      //mkl
      debugger
      this.$vs.loading();
      this.$store
        .dispatch("SurgeryList/SearchHospitalsPaging", this.search)
        .then((res) => {
          debugger
          this.$vs.loading.close();
          if (this.hospitals == null || this.hospitals.length == 0) {
            this.$vs.notify({
              title: this.$t("NoData"),
              text: this.$t("NoDataToshow"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          }
          if(res.data.Data.length==0){
          this.IsShowMore = false;
        }
    //  if (
    //       document.documentElement.scrollHeight <= window.innerHeight+750 &&
    //       res.data.Data.length >= this.search.PageSize
    //     ) {
    //       debugger
    //       this.search.PageNumber++;
    //       this.searchSurAndTreat();
    //     }
        });
   },
    filterSurgeries() {
      this.$vs.loading();

      this.search.PageNumber = 1;
      this.$store.commit("SurgeryList/SET_SearchHospitals", []);

      this.$store
        .dispatch("SurgeryList/SearchHospitalsPaging", this.search)
        .then((re) => {
          this.$vs.loading.close();
        });
    },
    UpdateSelectedSurgCategoryColor(ID) {
      this.search.SurgeryID = null;

      this.SimpleSearchHospitals();
      this.GetAllAppointmentSurgeryCountries()
      // debugger;
      // if (ID) {
      //   this.ActiveIndex = this.surgeryCategories.findIndex((i) => i.ID === ID);
      //   var Obj = {
      //     SurgeryCategoryID: ID,
      //   };
      //   this.search.SurgeryID = null;
      //   // this.$store.dispatch("SurgeryList/GetSurgerysByCategory", Obj);
      // } else {
      //   this.ActiveIndex = null;
      //   this.search.SurgeryCategoryID = "";
      // }
    },

    SearchHospitals(ID) {
      debugger;
      this.CountryActiveIndex = this.countries.findIndex((i) => i.ID === ID);
      var Obj = {
        countryID: ID,
      };

      this.$store.dispatch("CountryList/SearchHospitals", Obj).then(() => {
      
      });
    },
    selectDestination(destination) {
      this.search.CountryID = destination.ID;
    },

    selectCountry(destination, index) {
      if (destination) {
        this.search.CountryID = destination.ID;
        this.CountryActiveIndex = index;
      } else {
        this.CountryActiveIndex = null;
        this.search.CountryID = "";
      }
      debugger;
    },
    selectSurgery(destination, index) {
      if (destination) {
        this.search.SurgeryID = destination.ID;
        this.SurgActiveIndex = index;
      } else {
        this.SurgActiveIndex = null;
        this.search.SurgeryID = "";
      }
    },
    SimpleSearchHospitals(){
      this.$store.dispatch("HospitalList/SimpleSearchHospitals", this.search)
      this.UpdateData()
    },
    GetAllAppointmentSurgeryCountries(){
      this.$store.dispatch("CountryList/GetAllAppointmentSurgeryCountries", this.search);
    }
  },
  computed: {
    topDestinationsPage() {
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    hospitals() {
      debugger;
      return this.$store.state.SurgeryList.hospitals;
    },
    doctors() {
      return this.$store.state.patientList.doctors;
    },
    surgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
    surgeriesByCat() {
      return this.$store.state.SurgeryList.surgeriesByCat;
    },
    surgeries() {
      return this.$store.state.SurgeryList.surgeries;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    hospitalsList() {
      return this.$store.state.HospitalList.searchSimpleHospitals;
    },
    doctorCountries() {
      return this.$store.state.CountryList.doctorCountries;
    },
    topDestinations() {
      return this.$store.state.CountryList.topDestinations;
    },
  },
  created() {
    debugger
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleSurgery.isRegistered) {
      this.$store.registerModule("SurgeryList", moduleSurgery);
      moduleSurgery.isRegistered = true;
    }
       debugger
     this.$store.dispatch("SurgeryList/GetSurgerysByCategory", {'SurgeryCategoryID':null});

    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store.commit("SurgeryList/SET_SearchHospitals", []);
    
      //if(!this.$store.state.SurgeryCategoryList.surgeryCategories||this.$store.state.SurgeryCategoryList.surgeryCategories.length==0)
   // this.$store.dispatch("SurgeryCategoryList/GetAllSurgeryCategories");

  // if(!this.$store.state.CountryList.doctorCountries||this.$store.state.CountryList.doctorCountries.length==0)
  //   this.$store.dispatch("CountryList/GetDoctorCountry");

  // if(!this.$store.state.CountryList.countries||this.$store.state.CountryList.countries.length==0)
  //   this.$store.dispatch("CountryList/GetAllHospitalSurgeryCountries");

    const CountryID = this.$route.params.CountryID;
    const SpecialtyID = this.$route.params.SpecialtyID;
    if (CountryID != undefined || SpecialtyID != undefined) {
      if (SpecialtyID != undefined) {
        this.search.SpecialtyID = parseInt(SpecialtyID);
      }

      if (CountryID != undefined){
          this.search.CountryID = parseInt(CountryID);
      } 
        this.$store.state.SurgeryList.search = this.search;
    }
    else{
     this.search=   this.$store.state.SurgeryList.search;
    }
    // this.SearchHospitals(CountryID);
    this.SimpleSearchHospitals();
    
    this.searchSurAndTreat();
    this.UpdateSpecialities()
    
   this.GetAllAppointmentSurgeryCountries()
    
  },
  mounted() {
    window.onscroll = () => {
      myFunction()
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 200 >=
        document.documentElement.scrollHeight;
      if (bottomOfWindow) {
        if (this.hospitals.length > 0) {
          // this.search.PageNumber++;
          // this.getNextHospitals();
        }
      }
    };
       function myFunction() {
           var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
        }
  },
  destroyed() {
    window.onscroll = () => {
         var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
    };
  },
};
</script>
<style  >
.searchPosition
{
  position: absolute;
  top: 278px;
  z-index: 107;
  width: 73%;
}
#SAT .SliderStyleMobile{
  width: 100%;
  z-index: 1;
}
#SAT .SliderStyle{
  position: absolute;
      top: 0px;
    z-index: 90;
    left: 89px;
  width: 90vw;
   height: 49vh;
  object-fit: cover;
}
</style>
<style>
.sr-only[data-v-fde73a0c] {
  left: 0px !important;
}
.sr-only {
  left: 0px !important;
}
#SurgerySearch .vs-list--slot {
  width: 100%;
}
#SurgerySearch .vs-list {
  padding: 0px;
}
#SurgerySearch .vs-card--content {
  padding: 0px;
}
#SurgerySearch .listScroll {
  height: 170px;
  overflow-y: scroll;
}
#SurgerySearch .ListHeader {
  height: 46px;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
}
#SurgerySearch .listItem {
  height: 40px;
  cursor: pointer;
  padding: 10px;
}
::-webkit-scrollbar {
  height: 5px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#searchdiv {
  margin: -56px 31px;
}
.vs__dropdown-toggle {
  background: white;
  border: 2px solid #454a62;
  border-radius: 9px;
}
</style>

<style>
.imageDiv {
  display: block;
}

#SurgerySearch .shadowDiv {
  position: relative;
  height: 115px;
  bottom: 120px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
}
</style>
<style>
#specialyMobile .vs__dropdown-toggle {
  border-radius: unset;
}
#destinationMobile .vs__dropdown-toggle {
  border-radius: 18px;
}
</style>

<style scoped>
.socialImage {
  width: 50px;
  height: 50px;
}

.searchCard {
  height: flex;
  border-radius: 18px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000003d;

  border-radius: 24px;
  opacity: 1;
}
.searchCardMobil {
  height: flex;

  border-radius: 18px;
}
.hospitalListMobil {
  padding-top: 20px;
}
.searchButton {
  height: 80px;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
}
.searchButtonMobil {
  height: 50px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}
.Hdivider {
  background: #00000029;
  width: 100%;
  height: 2px;
  margin-top: 5px;
}
.divider {
  background: #00000029;
  width: 3px;
  height: 80px;
}
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

#SAT .vs-inputx {
  background-color: $primary;
}
#SAT .input-span-placeholder {
  color: white;
}
</style>
<style>
.vue-flux {
  height: 80vh;
}
#SAT .vs__dropdown-toggle {
  border: 0px solid #454a62 !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#SAT .v-select .vs__dropdown-toggle .vs__search {
  font-size: 15px;
}
#SAT .vs__selected {
  font-size: 1rem;
  white-space: nowrap;
  font-weight: bold !important;
  padding-top: 0.1rem;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
