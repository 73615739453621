import { render, staticRenderFns } from "./SurgeryAndTreatment.vue?vue&type=template&id=20f66f49&scoped=true"
import script from "./SurgeryAndTreatment.vue?vue&type=script&lang=js"
export * from "./SurgeryAndTreatment.vue?vue&type=script&lang=js"
import style0 from "./SurgeryAndTreatment.vue?vue&type=style&index=0&id=20f66f49&prod&lang=css"
import style1 from "./SurgeryAndTreatment.vue?vue&type=style&index=1&id=20f66f49&prod&lang=css"
import style2 from "./SurgeryAndTreatment.vue?vue&type=style&index=2&id=20f66f49&prod&lang=css"
import style3 from "./SurgeryAndTreatment.vue?vue&type=style&index=3&id=20f66f49&prod&lang=css"
import style4 from "./SurgeryAndTreatment.vue?vue&type=style&index=4&id=20f66f49&prod&scoped=true&lang=css"
import style5 from "./SurgeryAndTreatment.vue?vue&type=style&index=5&id=20f66f49&prod&lang=scss"
import style6 from "./SurgeryAndTreatment.vue?vue&type=style&index=6&id=20f66f49&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f66f49",
  null
  
)

export default component.exports