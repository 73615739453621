<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="surPop">

      <div class="justify-center  items-center surPopcustomBG" :style="[ windowWidth<=500? {'width':'98%'}: {'width':'50%','margin':'auto'}]">
        <div  style="border-radius: 15px" >
          <div class="p-5">
            <div class="MyPopClose">
                    <feather-icon class="mt-5 " style="color:black" icon="XIcon" svgClasses="w-10 h-10 stroke-current" @click.stop="$emit('closePop')" />
            </div>
            <h4 class="text-center  mb-10" style="color:white" > <u class="mr-4"> {{itemModel.CatName}} </u>   </h4>
            <div
           v-for="(col, index) in itemModel.Surgeries"   :key="index">


              <div class="vx-row mt-2 ">
              <div class="vx-col  lg:w-1/2 sm:w-full" >
                <h4 class="ml-3" style="color:white;   padding: 4px 4px 4px;">{{ col.Surgery.Name }}</h4>
                </div>
                     <div class="vx-col  mb-4 lg:w-1/2 sm:w-full"  >
                    <vs-button
                      color="#00C524"
                      style="width:11rem;height:3.2rem;padding:2px;margin-top:-6px"
                      class=""
                      @click="
                        $router.push({
                          name: 'TreatmentsProfile',
                          params: { ID: col.ID },
                        })
                      ">
                        <feather-icon    style="color:white;"  icon="EyeIcon" svgClasses=" h-4 stroke-current"  /> <span class="mr-3">{{$t("viewsurgery")}}  </span>
                     </vs-button>

                </div>
               </div>

            </div>

          </div>
        </div>

    </div>
  </div>
</template>

<script>
import Specialty from '../../settings/specialty/Specialty.vue';

export default {
  data() {
    return {

    };
  },
  props: {
    itemModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {Specialty},
  computed: {
  windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  created() {

  },
};
</script>

<style >

#surPop .surPopcustomBG{
    background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
</style>
