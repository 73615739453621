<template>
  <div id=surgeryList>
    <vx-card v-for="(item, index) in data" :key="index.ID" class="mb-base">
      <div class="vx-row lg:px-24 md:px-20 sm:px-12 px-6">
       
       <div class="vx-col lg:w-2/3  w-full">
        
          <imageLazy
                :imageStyle="{ 'border-radius': '20px','height':'18vh' }"
                :imageClass="'w-full'"
                :src="baseURL + item.ImagePath"
                placeHolderType="hospital"
                @click="$router.push('/hospitalprofile/'+item.ID)"
              />
         
              <h2 class="font-bold mt-3 greenColor" style="cursor:pointer; text-decoration: underline;" @click="$router.push('/hospitalprofile/'+item.ID)">{{ item.Name }}</h2>
              <div class="vx-row mt-1">
              <h5 class="lg:w-1/2 w-full" style="color:#696688">{{$t('Rate')}} :</h5>      

              <rate :rateValue="item.Rate"/>
              
              </div>
              <div class="vx-row">
              <h5 class="lg:w-1/2 w-full mb-1" style="color:#696688">{{$t('RecommendationLevel')}} : </h5>     
                <rate :rateValue="item.RecommendedLevel"/>
              </div>
              <p class="vx-col" v-if="item.HospitalInfo" v-html="item.HospitalInfo.substring(0, item.numberOfWords==undefined? 300 : item.numberOfWords) ">

              </p>
             <u style="color:#00C524" v-if="item.numberOfWords==undefined" @click="updateNumberOfWords(item)">{{$t('ShowMore')}}</u>

            
      </div>

      <div  class="vx-col lg:w-1/3  w-full mt-10">
        <vs-button
           v-for="(col, index) in item.SurgeryCategorys"   :key="index"
          class="w-full m-4 "  
          vs-w="3"
          vs-sm="5"
         
         @click="$emit('getSurgeries',col.ID,item.ID)"
        >
         {{col.Name}}
         
        </vs-button>
      </div>
      </div>
     
    </vx-card>
 
  </div>
</template>
<script>


import { domain } from "@/gloabelConstant.js";
import hospitalSurgeries from "./HospitalSurgeriesPopup.vue"
import imageLazy from "@/components/image_lazy.vue";
export default {
    components:{
           hospitalSurgeries,
  },
  data() {
    return {
    
      baseURL: domain,
     
      StatusList: [
        {
          Code: 1,
          Value: "Recommended",
        },
        {
          Code: 2,
          Value: "Highest Price",
        },
        {
          Code: 3,
          Value: "Lowest Price",
        },
        {
          Code: 4,
          Value: "Rate",
        },
      ],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  components: {
           imageLazy,
  },
  methods: {
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    updateNumberOfWords(mode){
       this.$set(mode, "numberOfWords", 200000);
    }
  }
};
</script>
<style>
#surgeryList .vx-row
{
  margin: unset;
}
.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}
</style>
